import React, { useState, useEffect } from 'react';
import { Modal, Carousel, Button, ModalFooter } from 'react-bootstrap';
import moment from 'moment';
import './PostDetailModel.css';
import { useNavigate } from 'react-router-dom';

const PostDetailModel = ({ show, handleClose, post }) => {
  const navigate = useNavigate();
  const [currentPost, setCurrentPost] = useState(post);

  useEffect(() => {
    if (post) {
      console.log("Initial post set:", post);
      setCurrentPost(post);
    }
  }, [post]);
  

  if (!currentPost) return null;

  const handleReport = () => {
    navigate(`/Contact?ad_id=${currentPost.id}`);
  };

  // const handleNavigate = (ad) => {
  //   if (ad) {
  //     console.log("Navigating to ad:", ad);
  //     // If necessary, fetch the new ad details here or update state accordingly
  //     setCurrentPost(ad);
  //   } else {
  //     console.log("No ad to navigate to.");
  //   }
  // };
  console.log("Current Post:", currentPost);
  console.log("Previous Ad:", currentPost.previous_ad);
  console.log("Next Ad:", currentPost.next_ad);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="profile-info-postsmodel">
            <img
              src={currentPost.user.profile_photo}
              alt={`${currentPost.user.username}'s profile`}
              className="profile-photo-postsmodel"
            />
            <span className="username">{currentPost.user.username}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="post-detail">
          <div className="details">
            <Button variant="danger" className="ml-auto report-button" onClick={handleReport}>
              Report
            </Button>
            <p><strong>Ad ID:</strong> {currentPost.id}</p>
            <p><strong>Title:</strong> {currentPost.title}</p>
            <p><strong>Description:</strong> {currentPost.description}</p>
            <p><strong>Mobile:</strong> {currentPost.mobile}</p>
            <p><strong>Street:</strong> {currentPost.street}</p>
            <p><strong>City:</strong> {currentPost.city}</p>
            <p><strong>Pincode:</strong> {currentPost.pincode}</p>
            <p><strong>State:</strong> {currentPost.state}</p>
            <p className="created-date"><strong>Posted:</strong> {moment(currentPost.created).fromNow()}</p>
          </div>
          {currentPost.files && currentPost.files.length > 0 && (
            <Carousel>
              {currentPost.files.map((file, index) => (
                <Carousel.Item key={index}>
                  <img className="d-block w-100" src={file.file} alt={`Slide ${index + 1}`} />
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </div>
      </Modal.Body>
      {/* <ModalFooter>
      <Button 
    variant="secondary" 
    onClick={() => handleNavigate(currentPost.previous_ad)}
    disabled={!currentPost.previous_ad}
  >
    {currentPost.previous_ad ? `Previous Ad: ${currentPost.previous_ad.title}` : 'No Previous Ad'}
  </Button>
  <Button 
    variant="secondary" 
    onClick={() => handleNavigate(currentPost.next_ad)}
    disabled={!currentPost.next_ad}
  >
    {currentPost.next_ad ? `Next Ad: ${currentPost.next_ad.title}` : 'No Next Ad'}
  </Button>
      </ModalFooter> */}
    </Modal>
  );
};

export default PostDetailModel;
