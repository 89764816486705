import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import { Container, Button, Form } from "react-bootstrap";
import apiUrl from "../Config";
import "./style.css";
import case3im from "../../Assets/Picture.png";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import BaseMain from "../Base/Base";


const Loading = () => (
  <div className="loading-container">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);

function CreateAd() {
  const [currentStep, setCurrentStep] = useState(1);
  const [user, setUser] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [selectedSubCategory1, setSelectedSubCategory1] = useState("");
  const [selectedSubCategory2, setSelectedSubCategory2] = useState("");
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories1, setSubCategories1] = useState([]);
  const [subCategories2, setSubCategories2] = useState([]);
  const [files, setFiles] = useState([]);
  const [mobile, setMobile] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      setSnackbarMessage("You need to log in to access this page.");
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);

      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      fetchMainCategories();
      setUser(localStorage.getItem("pk"));
    }
  }, [navigate]);

  const fetchMainCategories = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("accessToken");
      const response = await axios.get(`${apiUrl}/api/main-categories/`);
      console.log("Main Categories Response:", response.data); // Log the response data
      setMainCategories(response.data);
    } catch (error) {
      console.error("Error fetching main categories:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubCategories1 = async (mainCategory) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/main-categories/${mainCategory}/`
      );
      
      const subCategories = response.data.SubCategories || [];
      console.log(
        `Subcategories1 Response for ${mainCategory}:`,
        subCategories
      );
      setSubCategories1(subCategories);
      if (subCategories.length > 0) {
        setSelectedSubCategory1(subCategories[0]);
        fetchSubCategories2(mainCategory, subCategories[0].id);
      } else {
        setSelectedSubCategory1(null);
      }
    } catch (error) {
      console.error(
        `Error fetching subcategories1 for ${mainCategory}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchSubCategories2 = async (mainCategory, subCategory1) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/main-categories/${mainCategory}/${subCategory1}/`
      );
      // Log the full response data to verify the structure
      console.log(
        `Subcategories2 Response for ${mainCategory}/${subCategory1}:`,
        response.data
      );
      const subCategories = response.data.SubCategories || [];
      console.log(`Subcategories2 Data Extracted:`, subCategories);
      setSubCategories2(subCategories);
    } catch (error) {
      console.error(
        `Error fetching subcategories for ${mainCategory}/${subCategory1}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("user", user);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("main_category", selectedMainCategory.value);
      formData.append("sub_category1", selectedSubCategory1.value);
      if (
        selectedSubCategory2 &&
        !subCategories2.some((cat) => cat.id === selectedSubCategory2.value)
      ) {
        throw new Error("Selected SubCategory2 ID does not exist.");
      }
      formData.append("mobile", mobile);
      formData.append("street", street);
      formData.append("city", city);
      formData.append("pincode", pincode);
      formData.append("state", state);
      files.forEach((file) => formData.append("files", file));
      for (var pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const authToken = localStorage.getItem("accessToken");

      const response = await axios.post(`${apiUrl}/api/free-ads/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("Ad created successfully:", response.data);
      setSnackbarMessage("Ad created successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      setTimeout(() => navigate("/Home"), 2000);
    } catch (error) {
      console.error("Error creating ad:", error);
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const invalidFiles = Array.from(selectedFiles).filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return !allowedExtensions.includes(extension);
    });

    if (invalidFiles.length > 0) {
      setErrorMessage("Please upload only JPEG, JPG, or PNG files.");
      event.target.value = "";
      return;
    }

    setErrorMessage("");
    setFiles([...files, ...selectedFiles]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target || e;

    switch (name) {
      case "title":
        setTitle(value);
        setFieldErrors({
          ...fieldErrors,
          title:
            value.trim().length > 6
              ? ""
              : "Title must contain more than 6 characters.",
        });
        break;
      case "description":
        setDescription(value);
        setFieldErrors({
          ...fieldErrors,
          description:
            value.trim().length > 20
              ? ""
              : "The description must contain more than 20 characters.",
        });
        break;
      case "mainCategory":
        setSelectedMainCategory(value);
        setFieldErrors({
          ...fieldErrors,
          mainCategory: value ? "" : "Please select a main category.",
        });
        break;
      case "subCategory1": // Handling for subCategory1
        setSelectedSubCategory1(value);
        setFieldErrors({
          ...fieldErrors,
          subCategory1: value ? "" : "Please select a subcategory.",
        });
        break;
      case "subCategory2": // Handling for subCategory1
        setSelectedSubCategory2(value);
        setFieldErrors({
          ...fieldErrors,
          subCategory2: value ? "" : "Please select a subcategory.",
        });
        break;
      case "mobile":
        setMobile(value);
        setFieldErrors({
          ...fieldErrors,
          mobile:
            value.trim().length >= 10
              ? ""
              : "Please enter a valid mobile number.",
        });
        break;
      case "street":
        setStreet(value);
        setFieldErrors({
          ...fieldErrors,
          street: value.trim().length > 0 ? "" : "Please enter your street.",
        });
        break;
      case "city":
        setCity(value);
        setFieldErrors({
          ...fieldErrors,
          city: value.trim().length > 0 ? "" : "Please enter your city.",
        });
        break;
      case "pincode":
        setPincode(value);
        setFieldErrors({
          ...fieldErrors,
          pincode: value.trim().length > 0 ? "" : "Please enter your pincode.",
        });
        break;
      case "state":
        setState(value);
        setFieldErrors({
          ...fieldErrors,
          state: value.trim().length > 0 ? "" : "Please enter your state.",
        });
        break;
      default:
        break;
    }
  };

  const nextStep = () => {
    let errors = {};

    if (currentStep === 1) {
      if (title.trim().length <= 6) {
        errors.title = "Title must contain more than 6 characters.";
      }
      if (description.trim().length <= 20) {
        errors.description =
          "The description must contain more than 20 characters.";
      }
    } else if (currentStep === 2 && !selectedMainCategory) {
      errors.mainCategory = "Please select a main category.";
    } else if (currentStep === 3) {
      if (subCategories1.length < 0 && !selectedSubCategory1) {
        errors.subCategory1 = "Please select a subcategory.";
      }
    } else if (currentStep === 4) {
      if (subCategories2.length === 0 || !selectedSubCategory1) {
        setCurrentStep(currentStep + 1);
        return;
      }
    } else if (currentStep === 5) {
      if (currentStep === 5) {
        if (files.length === 0) {
            setErrorMessage("Please upload at least one file.");
            return;
        }
        if (files.length > 5) {
            setErrorMessage("You can upload up to 5 files.");
            return;
        }
    }
    } else if (currentStep === 6) {
      if (!mobile) {
        errors.mobile = "Please enter your mobile number.";
      }
      if (!street) {
        errors.street = "Please enter your street.";
      }
      if (!city) {
        errors.city = "Please enter your city.";
      }
      if (!pincode) {
        errors.pincode = "Please enter your pincode.";
      }
      if (!state) {
        errors.state = "Please enter your state.";
      }
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    setCurrentStep(currentStep + 1);
    setFieldErrors({});
  };

  const prevStep = () => {
    if (currentStep === 5) {
      setCurrentStep(3);
    } else {
      setCurrentStep(currentStep - 1);
    }
    setErrorMessage("");
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: fieldErrors.mainCategory ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: fieldErrors.mainCategory ? "red" : provided.borderColor,
      },
    }),
  };

  const handleSubCategory1Change = (selectedOption) => {
    setSelectedSubCategory1(selectedOption);
    fetchSubCategories2(selectedMainCategory.value, selectedOption.value); // Fetch subcategories based on selected subcategory1
  };

  const handleSubCategory2Change = (selectedOption) => {
    setSelectedSubCategory2(selectedOption);
  };

  const handleMainCategoryChange = (selectedOption) => {
    setSelectedMainCategory(selectedOption);
    fetchSubCategories1(selectedOption.value);
  };
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Container className="case1-container">
            <h3 className="h3-case1">Enter Your Title & Description</h3>

            <Form.Group controlId="title">
              <Form.Control
                className={`title-form ${
                  fieldErrors.title ? "error-border" : ""
                }`}
                type="text"
                value={title}
                onChange={handleChange}
                name="title"
                placeholder="Title"
              />
              <div className="Error-text">
                <div className="Error-text2">
                  {fieldErrors.title && (
                    <div className="error-message">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon"
                      />
                      {fieldErrors.title}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId="description" style={{ marginTop: "20px" }}>
              <Form.Control
                className={`descr-form ${
                  fieldErrors.description ? "error-border" : ""
                }`}
                as="textarea"
                rows={6}
                value={description}
                onChange={handleChange}
                name="description"
                placeholder="Type your description..."
                style={{ width: "100%" }}
              />
              <div className="Error-text">
                <div className="Error-text2">
                  {fieldErrors.description && (
                    <div className="error-message">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon"
                      />
                      {fieldErrors.description}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>
            <div className="case1-button-container ">
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );
      case 2:
        return (
          <Container className="case2-container">
            <h3 className="h3-case2">Select Main Category</h3>

            <Form.Group controlId="mainCategory">
              <Select
                className="mainCategory-form"
                value={selectedMainCategory}
                onChange={handleMainCategoryChange}
                options={mainCategories.map((category) => ({
                  value: category.id,
                  label: category.MainCategory,
                }))}
              />
              <div className="Error-text">
                <div className="Error-text2">
                  {fieldErrors.mainCategory && !selectedMainCategory && (
                    <div className="error-message">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon"
                      />
                      {fieldErrors.mainCategory}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>

            <div className="button-container">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );
      case 3:
        return (
          <Container className="case2-container">
            <h3 className="h3-case2">Select Sub Category</h3>

            {selectedMainCategory && (
              <Form.Group controlId="subCategory1">
                <Select
                  className="mainCategory-form"
                  value={selectedSubCategory1}
                  onChange={handleSubCategory1Change}
                  options={subCategories1.map((category) => ({
                    value: category.id,
                    label: category.SubCategory,
                  }))}
                  isDisabled={!selectedMainCategory}
                />
                <div className="Error-text">
                  <div className="Error-text2">
                    {fieldErrors.subCategory1 && (
                      <div className="error-message">
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          className="error-icon"
                        />
                        {fieldErrors.subCategory1}
                      </div>
                    )}
                  </div>
                </div>
              </Form.Group>
            )}

            <div className="button-container">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );

      case 4:
        if (subCategories2.length === 0) {
          nextStep();
          return null;
        }
        return (
          <Container className="case2-container">
            <h3 className="h3-case2">Select Sub Category || </h3>

            {selectedSubCategory1 && subCategories2.length > 0 && (
              <Form.Group controlId="subCategory2">
                <Select
                  value={selectedSubCategory2}
                  onChange={(selectedOption) =>
                    setSelectedSubCategory2(selectedOption)
                  }
                  options={subCategories2.map((category) => ({
                    value: category.id,
                    label: category.SubCategory,
                  }))}
                />
              </Form.Group>
            )}

            <div className="button-container">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );
      case 5:
        return (
          <Container className="case3-container">
            <h3 className="h3-case1">Upload Images</h3>
            <div className="dot_rec" onClick={handleImageClick}>
              <img src={case3im} alt="image" className="dotrecimg" />
              <div className="droptext">
                <div className="droptext1">Drop your image here, or</div>
                <div className="droptext2"> browse</div>
              </div>

              <div className="droptext3">Supports: PNG, JPG, JPEG, WEBp</div>

              <input
                type="file"
                multiple
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>

            {errorMessage && (
              <div className="Error-text">
                <div className="Error-text2">
                  <div className="error-message">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="error-icon"
                    />
                    {errorMessage}
                  </div>
                </div>
              </div>
            )}

            <div className="button-container ">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );
      case 6:
        return (
          <Container className="case4-container">
            <h3 className="h3-case4">Address Details</h3>

            <Form.Group controlId="mobile">
              <Form.Control
                className={`mobile-form ${
                  fieldErrors.mobile ? "error-border" : ""
                }`}
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Mobile"
              />

              <div className="Error-text4">
                <div className="Error-text24">
                  {fieldErrors.mobile && (
                    <div className="error-message4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon4"
                      />
                      {fieldErrors.mobile}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="street">
              <Form.Control
                className={`street-form ${
                  fieldErrors.street ? "error-border" : ""
                }`}
                type="text"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                placeholder="Street"
              />
              <div className="Error-text4">
                <div className="Error-text24">
                  {fieldErrors.street && (
                    <div className="error-message4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon4"
                      />
                      {fieldErrors.street}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Control
                className={`city-form ${
                  fieldErrors.city ? "error-border" : ""
                }`}
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="City"
              />
              <div className="Error-text4">
                <div className="Error-text24">
                  {fieldErrors.city && (
                    <div className="error-message4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon4"
                      />
                      {fieldErrors.city}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId="pincode">
              <Form.Control
                className={`pincode-form ${
                  fieldErrors.pincode ? "error-border" : ""
                }`}
                type="text"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                placeholder="Pincode"
              />
              <div className="Error-text4">
                <div className="Error-text24">
                  {fieldErrors.pincode && (
                    <div className="error-message4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon4"
                      />
                      {fieldErrors.pincode}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="state">
              <Form.Control
                className={`state-form ${
                  fieldErrors.state ? "error-border" : ""
                }`}
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="State"
              />
              <div className="Error-text4">
                <div className="Error-text24">
                  {fieldErrors.state && (
                    <div className="error-message4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon4"
                      />
                      {fieldErrors.state}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>

            <div className="button-container ">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );
      case 7:
        return (
          <Container className="review-container">
            <h3 className="h7-header">Review Details & Sumbit</h3>

            <div className="review-section">
              <div>
                <div className="review-carousel-container">
                  <Carousel className="review-files-ca">
                    {Array.from(files).map((file, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="reviewfile-preview-image"
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
                <p>
                  <strong>Title:</strong> {title}
                </p>
                <p>
                  <strong>Description:</strong> {description}
                </p>

                <p>
                  <strong>Main Category:</strong> {selectedMainCategory.label}
                </p>
                <p>
                  <strong>Subcategory 1:</strong> {selectedSubCategory1.label}
                </p>
                {selectedSubCategory2 && (
                  <p>
                    <strong>Subcategory 2:</strong> {selectedSubCategory2.label}
                  </p>
                )}

                <p>
                  <strong>Mobile:</strong> {mobile}
                </p>
                <p>
                  <strong>Street:</strong> {street}
                </p>
                <p>
                  <strong>City:</strong> {city}
                </p>
                <p>
                  <strong>Pincode:</strong> {pincode}
                </p>
                <p>
                  <strong>State:</strong> {state}
                </p>
              </div>
            </div>

            <div className="button-container">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>

              <Button className="sub-ad-cnfrm case1button" type="submit">
                Submit
              </Button>
            </div>
          </Container>
        );

      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "170px" }}>
      <Container className="ads-container">
        <BaseMain />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={10000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        <Form onSubmit={handleSubmit}>{renderStep()}</Form>
      </Container>
    </div>
  );
}

export default CreateAd;
