// PasswordResetRequest.jsx
import React, { useState } from 'react';
import axios from 'axios';
import apiUrl from '../Config';
import "../Password/Password.css";
import Base from '../Base/Base';
import Footer from '../Footer/Footer';
const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/api/password/reset/`, { email });
      setMessage('Password reset link sent.');
      setMessageType('success'); // Set message type to success
    } catch (error) {
      setMessage('Error sending password reset link.');
      setMessageType('error'); // Set message type to error
    }
  };

  return (
    <> 
    <div className='resetbody'>
      <Base />
    <div style={{ marginTop: "470px" }}></div>
       {message && <p className={`message ${messageType}`}>{message}</p>}
      <form onSubmit={handleSubmit} className='resetform'>
        <input
          type="email"
          value={email}
          className='emailbar'
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button className="reset-button" type="submit">Reset Email</button>
      </form>
     

      <div className="about-footer">  
        <Footer />
      </div>
      </div>
  </>
  );
};

export default PasswordResetRequest;
