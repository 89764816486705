import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Container, Alert, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EyeIcon from "../../Assets/eye.png";
import EyeSlashIcon from "../../Assets/eye-slash.png";
import apiUrl from "../Config";
import "../Auth/Signup.css";
import klogo from "../../Assets/letter-k.png";

import { Link } from "react-router-dom";
import GoogleLogo from "../../Assets/g-icon.png";
import Base from "../Base/Base";

const Eye = () => <img src={EyeIcon} alt="Eye Icon" />;
const EyeSlash = () => <img src={EyeSlashIcon} alt="Eye Slash Icon" />;

const Signup = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [profile_photo, setProfilePhoto] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async () => {
    try {
      if (!email || !username || !password1 || !password2) {
        setErrorMessage(
          "All fields are required. Please fill in all the fields."
        );
        setSuccessMessage("");
        return;
      }

      const formData = new FormData();
      formData.append("email", email);
      formData.append("username", username);
      formData.append("password1", password1);
      formData.append("password2", password2);
      if (profile_photo) {
        formData.append("profile_photo", profile_photo);
      }

      // const response = await axios.post(`${apiUrl}/api/register/`, {
      //   email,
      //   username,
      //   password1,
      //   password2,
      //   profile_photo,
      // });


      const response = await axios.post(`${apiUrl}/api/register/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setSuccessMessage("Signup successful! Redirecting to login page.");
      setErrorMessage("");

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      if (error.response.data.email) {
        setErrorMessage(` ${error.response.data.email[0]}`);
      } else if (error.response.data.username) {
        setErrorMessage(` ${error.response.data.username[0]}`);
      } else if (error.response.data.password1) {
        setErrorMessage(` ${error.response.data.password1[0]}`);
      } else if (error.response.data.non_field_errors) {
        setErrorMessage(` ${error.response.data.non_field_errors[0]}`);
      }

      setSuccessMessage("");
    }
  };

  return (
    <>
      <Base />
      

        <Container className="main-container">
        <Container className="left-container">
        <Image
            src={klogo}
            classname="lo-im"
            alt="Logo"
            width="350"
            height="350"
          />
        </Container>
        <Container className="right-container">
          <div className="signup-99">
            <button className="google-button-container" type="button">
              <img className="button-content-aPF" src={GoogleLogo} alt="Google Logo" />
              <p className="sign-in-with-google-GG5">Sign up with Google</p>
            </button>
            <div className="separator-container">
              <span className="separator-line"></span>
              <span className="separator-text">or</span>
              <span className="separator-line"></span>
            </div>
            <Container>
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Form.Control
                type="email"
                placeholder="Email"
                className="custom-input-s"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Control
                type="text"
                placeholder="Username"
                className="custom-input-s"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="password-input-container">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="custom-input-s password-input"
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                />
                <div
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeSlash /> : <Eye />}
                </div>
              </div>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                className="custom-input-s"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
              <div className="f-text" style={{ cursor: "pointer" }}>
                <label htmlFor="profile-photo-input" className="f-text-sub-1">
                  Choose Profile Picture
                </label>
                <input
                  id="profile-photo-input"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => setProfilePhoto(e.target.files[0])}
                />
              </div>
              <br />
              <Button variant="light" className="signup" onClick={handleSignup}>
                Register
              </Button>
              <div className="button-w65" id="15:317">
                <Link to="/login" className="button-w65-sub-0">
                  Already have an account? <span className="button-w65-sub-1">Login</span>
                </Link>
              </div>
             
              
         
            </Container>
          </div>
          <div className="c-text">
          <span className="b-text-sub-1">By Proceeding, I agree </span>
          <span className="different-color-text">T&C, Privacy Policy</span>
        </div>
        </Container>

       
      </Container>
      





      
    </>
  );
};

export default Signup;
