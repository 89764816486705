// AdDetailModal.js
import React from 'react';
import '../AdDetailModel/AdDetailModel.css'; // Add your styling here
import { Modal, Carousel } from 'react-bootstrap';
import moment from 'moment';
import apiUrl from '../../Config';
import axios from 'axios';
function AdDetailModal({ ad,show, onClose }) {
  if (!ad) return null;


  const handleVerificationApproval = async () => {
    try {
      const userId = ad.user.id; // Adjust based on how you have user ID
      const adId = ad.id;
      const response = await axios.put(`${apiUrl}/api/ads/${userId}/${adId}/`, {
        is_verified: true
      });

      if (response.status === 200) {
        // Optionally, you can update the ad state or notify the user
        alert('Ad verified successfully');
        onClose(); // Close the modal after verification
      } else {
        alert('Failed to verify the ad');
      }
    } catch (error) {
      console.error('Error verifying ad:', error);
      alert('Error verifying ad');
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="profile-info-admodal">
            <img
              src={ad.user?.profile_photo}
              alt={`${ad.user?.username}'s profile`}
              className="profile-photo-admodal"
            />
            <span className="username">{ad.user?.username}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ad-detail">
          <p><strong>Ad ID:</strong> {ad.id}</p>
          <p><strong>Title:</strong> {ad.title}</p>
          <p><strong>Description:</strong> {ad.description}</p>
          <p><strong>Mobile:</strong> {ad.mobile}</p>
          <p><strong>Street:</strong> {ad.street}</p>
          <p><strong>City:</strong> {ad.city}</p>
          <p><strong>Pincode:</strong> {ad.pincode}</p>
          <p><strong>State:</strong> {ad.state}</p>
          <p className="created-date"><strong>Posted:</strong> {moment(ad.created).fromNow()}</p>
          
          {ad.files && ad.files.length > 0 && (
            <Carousel>
              {ad.files.map((file, index) => (
                <Carousel.Item key={index}>
                  <img className="d-block w-100" src={file.file} alt={`Slide ${index + 1}`} />
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </div>
      </Modal.Body>


      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={handleVerificationApproval}
        >
          Verify Ad
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AdDetailModal;
