import React, { useState, useEffect } from "react";
import axios from "axios";
import "../ADash/ADash.css";
import { Card } from "react-bootstrap"; // Import Bootstrap components
import apiUrl from "../../Config";
import moment from "moment";

function ADash() {
  const [selectedOption, setSelectedOption] = useState("Users");
  const [homePageAds, setHomePageAds] = useState([]);
  const [topAds, setTopAds] = useState([]);
  const [vdAds, setVdAds] = useState([]);
  const [newAdText, setNewAdText] = useState("");
  const [newAdImage, setNewAdImage] = useState(null);
  const [newVideoAd, setNewVideoAd] = useState(null);

  const fetchHomePageAds = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin-home-page-ads/`);
      console.log(response.data);
      setHomePageAds(response.data);
    } catch (error) {
      console.error("There was an error fetching the HomePageAd data!", error);
    }
  };

  const fetchTopAds = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin-top-ads/`);
      setTopAds(response.data);
    } catch (error) {
      console.error(
        "There was an error fetching the TopNavigationAd data!",
        error
      );
    }
  };

  const deleteAd = async (id) => {
    try {
      
      await axios.delete(`${apiUrl}/api/admin-top-ads/${id}/`);
      setTopAds(topAds.filter((ad) => ad.id !== id));
    } catch (error) {
      console.error("There was an error deleting the ad!", error);
    }
  };

  const createAd = async () => {
    try {

      const response = await axios.post(
        `${apiUrl}/api/admin-top-ads/`,
        { ad_text: newAdText },
        
      );
      setTopAds([...topAds, response.data]); 
      setNewAdText(""); 
    } catch (error) {
      console.error("There was an error creating the ad!", error);
    }
  };
  const createHomePageAd = async () => {
    if (!newAdImage) return;

    const formData = new FormData();
    formData.append("files", newAdImage);

    try {

      const response = await axios.post(
        `${apiUrl}/api/admin-home-page-ads/`,
        formData,
        {
          headers: {
     
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setHomePageAds([...homePageAds, response.data]);
      setNewAdImage(null);
    } catch (error) {
      console.error("There was an error creating the HomePageAd!", error);
    }
  };
  const deleteHomePageAd = async (id) => {
    try {

      await axios.delete(`${apiUrl}/api/admin-home-page-ads/${id}/`);
      setHomePageAds(homePageAds.filter((ad) => ad.id !== id));
    } catch (error) {
      console.error("There was an error deleting the HomePageAd!", error);
    }
  };

  const createVideoAd = async () => {
    if (!newVideoAd) return;

    const formData = new FormData();
    formData.append("ad_video", newVideoAd);

    try {
     
      const response = await axios.post(
        `${apiUrl}/api/admin-video-ads/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setVdAds([...vdAds, response.data]);
      setNewVideoAd(null); // Clear the selected video
    } catch (error) {
      console.error("There was an error creating the VideoAd!", error);
    }
  };

  const deleteVideoAd = async (id) => {
    try {
      
      await axios.delete(`${apiUrl}/api/admin-video-ads/${id}/`);
      setVdAds(vdAds.filter((ad) => ad.id !== id));
    } catch (error) {
      console.error("There was an error deleting the VideoAd!", error);
    }
  };

  const fetchVideoAds = async () => {
    try {
     
      const response = await axios.get(`${apiUrl}/api/admin-videos-ads/`);
      setVdAds(response.data);
    } catch (error) {
      console.error("There was an error fetching the VideoAd data!", error);
    }
  };

  useEffect(() => {
    if (selectedOption === "HomePageAd") {
      fetchHomePageAds();
    } else if (selectedOption === "TopNavigationAd") {
      fetchTopAds();
    } else if (selectedOption === "VideoAd") {
      fetchVideoAds();
    }
  }, [selectedOption]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const renderContent = () => {
    switch (selectedOption) {
      case "Users":
        return <div>Users Content</div>;
      case "Employees":
        return <div>Employees Content</div>;
      case "TopNavigationAd":
        return (
          <div>
            <input
              type="text"
              value={newAdText}
              onChange={(e) => setNewAdText(e.target.value)}
              placeholder="Enter new ad text"
            />
            <button onClick={createAd}>Create New Ad</button>
            {topAds.map((ad) => (
              <Card key={ad.id}>
                <Card.Body>
                  {ad.ad_text}
                  <button onClick={() => deleteAd(ad.id)}>Delete</button>
                </Card.Body>
                <span className="">
                  {moment(ad.created).format("MMMM Do YYYY, h:mm:ss a")}
                </span>
              </Card>
            ))}
          </div>
        );
      case "HomePageAd":
        return (
          <div>
            <input
              type="file"
              onChange={(e) => setNewAdImage(e.target.files[0])}
              accept="image/*"
              multiple
            />
            <button onClick={createHomePageAd}>Create New Image Ad</button>
            {homePageAds.map((ad) => (
              <Card key={ad.id}>
                <Card.Body className="ad-con">
                  {ad.ad_photos.map((photo) => (
                    <img
                      key={photo.id}
                      src={photo.ad_file}
                      alt="Home Ad"
                      style={{ width: "100%" }}
                      className="ad-media"
                    />
                  ))}
                  <button onClick={() => deleteHomePageAd(ad.id)}>
                    Delete
                  </button>
                  <span className="ad-scr">
                    {moment(ad.created).format("MMMM Do YYYY, h:mm:ss a")}
                  </span>
                </Card.Body>
              </Card>
            ))}
          </div>
        );
      case "VideoAd":
        return (
          <div>
            <input
              type="file"
              onChange={(e) => setNewVideoAd(e.target.files[0])}
              accept="video/*"
            />
            <button onClick={createVideoAd}>Create New Video Ad</button>
            {vdAds.map((ad) => (
              <Card key={ad.id}>
                <Card.Body>
                  <video className="ad-vd" width="100%" controls>
                    <source src={ad.ad_video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <button onClick={() => deleteVideoAd(ad.id)}>Delete</button>
                  <span className="ads-cre">
                    {moment(ad.created).format("MMMM Do YYYY, h:mm:ss a")}
                  </span>
                </Card.Body>
              </Card>
            ))}
          </div>
        );
      default:
        return <div>Select an option from the sidebar</div>;
    }
  };

  return (
    <>
      <div className="eeditcon">
        <div className="main-econtainer">
          <div className="sidebar">
            <button onClick={() => handleOptionClick("Users")}>Users</button>
            <button onClick={() => handleOptionClick("Employees")}>
              Employees
            </button>
            <button onClick={() => handleOptionClick("TopNavigationAd")}>
              Top Navigation Ad
            </button>
            <button onClick={() => handleOptionClick("HomePageAd")}>
              Home Page Ad
            </button>
            <button onClick={() => handleOptionClick("VideoAd")}>
              Video Ad
            </button>
          </div>
          <div className="main-body">{renderContent()}</div>
        </div>
      </div>
    </>
  );
}

export default ADash;
