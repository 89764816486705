import React from "react";
import Footer from "../../Footer/Footer";
import Base from "../../Base/Base";
import "../About/About.css";
function About() {
    return (
        <div className="about-container">
          <Base />
          <div className="about-content-container" style={{ marginTop: "150px" }}>
            <h4 className="about-head">About Klassifies.com</h4>
            <p className="about-content">
              Klassifies.com is a free classified ads website for cars, jobs, real estate, and everything else. Find what you're looking for or create your own ad for free! Unlike many other classified ads websites, Klassifies.com is 100% free to use. Free to post an ad, free to browse listings, free to contact sellers. We invest a great deal of time and effort in monitoring and filtering the content posted to the website, so you can be confident that you'll find only quality, relevant listings. Our goal at Klassifies.com is to make it as easy as possible to buy or sell anything.
            </p>
          </div>
          <div className="about-footer">
            <Footer />
          </div>
        </div>
      );
}

export default About;