// PasswordChange.jsx
import React, { useState } from 'react';
import axios from 'axios';
import apiUrl from '../Config';
import "../Password/Password.css";
import Base from '../Base/Base';
import Footer from '../Footer/Footer';


const PasswordChange = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (newPassword !== confirmPassword) {
        setMessage('Passwords do not match.');
        return;
      }
      await axios.post(`${apiUrl}/api/password/change/`, {
        current_password: currentPassword,
        new_password1: newPassword,
        new_password2: confirmPassword,
      });
      setMessage('Password changed successfully.');
    } catch (error) {
      setMessage('Error changing password.');
    }
  };

  return (
    <>
    <Base />
    <div style={{ marginTop: "170px" }}></div>

      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          placeholder="Current password"
          required
        />
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New password"
          required
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          required
        />
        <button type="submit">Change Password</button>
      </form>
      {message && <p>{message}</p>}
      <Footer />
      </>
  );
};

export default PasswordChange;
