import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Dashboard.css";
import apiUrl from "../../Config";
import AdDetailModal from "../AdDetailModel/AdDetailModel";
import { Card } from "react-bootstrap"; // Import Bootstrap components

function Dashboard() {
  const [notVerifiedAds, setNotVerifiedAds] = useState([]);
  const [verifiedAds, setVerifiedAds] = useState([]);
  const [notVerifiedNextPage, setNotVerifiedNextPage] = useState(null);
  const [verifiedNextPage, setVerifiedNextPage] = useState(null);
  const [selectedTab, setSelectedTab] = useState("notVerified");
  const [error, setError] = useState(null);
  const [selectedAd, setSelectedAd] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [adsToDisplay, setAdsToDisplay] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state

  const adsContainerRef = useRef(null); // Ref for the ads container

  const fetchAds = async (url, setAds, setNextPage, setDisplayAds) => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      const response = await axios.get(url);
      console.log(response.data);
      const { results, next, previous } = response.data;
      setNextPage({ next, previous });
      setDisplayAds(results);

      
      if (adsContainerRef.current) {
        adsContainerRef.current.scrollTop = 0;
      }
    } catch (error) {
      console.error("Error fetching ads:", error);
      setError("Failed to load ads. Please try again later.");
    } finally {
      setLoading(false); 
    }
  };

  const loadAds = (tab, url) => {
    if (tab === "notVerified") {
      fetchAds(url, setNotVerifiedAds, setNotVerifiedNextPage, setAdsToDisplay);
    } else {
      fetchAds(url, setVerifiedAds, setVerifiedNextPage, setAdsToDisplay);
    }
  };
  

  useEffect(() => {
    const initialUrl = selectedTab === "notVerified"
      ? `${apiUrl}/api/ads/not-verified/`
      : `${apiUrl}/api/ads/verified/`;
    loadAds(selectedTab, initialUrl);
  }, [selectedTab]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    const url = tab === "notVerified"
      ? `${apiUrl}/api/ads/not-verified/`
      : `${apiUrl}/api/ads/verified/`;
    loadAds(tab, url);
  };

  const handleAdClick = (ad) => {
    console.log('Selected Ad:', ad);
    setSelectedAd(ad);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedAd(null);
    setShowModal(false);
  };

  const handlePageChange = (direction) => {
    if (selectedTab === "notVerified") {
      const url = direction === "next"
        ? notVerifiedNextPage?.next
        : notVerifiedNextPage?.previous;
      if (url) {
        fetchAds(url, setNotVerifiedAds, setNotVerifiedNextPage, setAdsToDisplay);
      }
    } else {
      const url = direction === "next"
        ? verifiedNextPage?.next
        : verifiedNextPage?.previous;
      if (url) {
        fetchAds(url, setVerifiedAds, setVerifiedNextPage, setAdsToDisplay);
      }
    }
  };

  
  return (
    <>
      <div className="eeditcon">
        <div className="main-econtainer">
          <div className="sidebar">
            <button
              className={selectedTab === "verified" ? "active" : ""}
              onClick={() => handleTabClick("verified")}
            >
              Verified Ads
            </button>
            <button
              className={selectedTab === "notVerified" ? "active" : ""}
              onClick={() => handleTabClick("notVerified")}
            >
              Not Verified Ads
            </button>
          </div>
          <div className="main-body">
            {error && <p className="error-message">{error}</p>}
            <div ref={adsContainerRef} className="admin-container">
              <h2>{selectedTab === "notVerified" ? "Not Verified Ads" : "Verified Ads"}</h2>
              {adsToDisplay.map((ad, index) => (
                <Card
                  key={index}
                  className="admin-card"
                  onClick={() => {
                    console.log("Card clicked:", ad); 
                    handleAdClick(ad);
                  }}
                >
                  {ad.image && <Card.Img variant="top" src={ad.image} alt={ad.title} />}
                  <Card.Body>
                    <Card.Title>{ad.title}</Card.Title>
                    <Card.Text>{ad.description}</Card.Text>
                  </Card.Body>
                </Card>
              ))}
              {loading && <div className="loading-indicator">Loading...</div>}
              <div className="pagination-controls">
                <button
                  onClick={() => handlePageChange("previous")}
                  disabled={!((selectedTab === "notVerified" ? notVerifiedNextPage : verifiedNextPage)?.previous)}
                >
                  Previous
                </button>
                <button
                  onClick={() => handlePageChange("next")}
                  disabled={!((selectedTab === "notVerified" ? notVerifiedNextPage : verifiedNextPage)?.next)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        {showModal && selectedAd && (
          <AdDetailModal ad={selectedAd} onClose={handleCloseModal} />
        )}
      </div>
    </>
  );
}

export default Dashboard;
