import React, { useEffect, useState } from "react";
import axios from "axios";
import apiUrl from "../Config";
import { useParams } from "react-router-dom";
import { Card, Container, Spinner, Pagination } from "react-bootstrap";
import moment from "moment";
import Base from "../Base/Base";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Footer from "../Footer/Footer";
import "../Posts/Posts.css";
import PostDetailModal from "../PostDetailsModel/PostDetailModel";
import NoResultImg from "../../Assets/Search.png";

function Posts() {
  const { subCategory1Id, subCategory } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);

  const handlePostClick = (post) => {
    setSelectedPost(post);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPost(null);
  };

  const handlePageChange = (url) => {
    if (url) fetchPosts(url);
  };

  const fetchPosts = async (url) => {
    setLoading(true);
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        setPosts(response.data.results);
        setNextUrl(response.data.next);
        setPreviousUrl(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / 10));
        setCurrentPage(new URLSearchParams(url.split("?")[1]).get("page") || 1);
      } else {
        console.error("Failed to fetch posts. Status code:", response.status);
      }
    } catch (err) {
      console.error("Error fetching posts:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (subCategory1Id) {
      const url = `${apiUrl}/api/posts/bysubcategory1/${subCategory1Id}/`;
      fetchPosts(url);
    }
  }, [subCategory1Id]);

  return (
    <>
      <Base />
      <div style={{ marginTop: "170px" }}></div>
      <Container className="posts-container">
        <div className="nav-text">
          <a href="/Home">Home</a> &gt; {subCategory}
        </div>
        {loading ? (
          <Spinner animation="border" />
        ) : posts.length > 0 ? (
          posts.map((post) => (
            <div className="postcard-container" key={post.id}>
              <Card
                className="post-item mb-3"
                onClick={() => handlePostClick(post)}
              >
                <Card.Body className="d-flex align-items-start">
                  <div className="profile-info">
                    <img
                      src={post.user.profile_photo}
                      alt={`${post.user.username}'s profile`}
                      className="profile-photo"
                    />
                  </div>
                  <div className="card-content flex-grow-1 ml-3">
                    <Card.Title>{post.title}</Card.Title>
                    <Card.Text className="post-city">
                      <i className="fas fa-map-marker-alt"></i> {post.city}
                    </Card.Text>
                    <Card.Text className="card-des">
                      {post.description.length > 40
                        ? post.description.substring(0, 45) + "..."
                        : post.description}
                    </Card.Text>
                    <div className="uds-details">
                      <Card.Text className="date-post">
                        {moment(post.created).fromNow()}
                      </Card.Text>
                      <Card.Text className="ad-username">
                        posted by {post.user.username}
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))
        ) : (
          <div>
            <img src={NoResultImg} alt="No Results Found" />
            <p className="nopostresult">No posts available.</p>
          </div>
        )}
        {selectedPost && (
          <PostDetailModal
            show={showModal}
            handleClose={handleCloseModal}
            post={selectedPost}
          />
        )}
        <div className="pagination">
          <Pagination>
            {previousUrl && (
              <Pagination.Prev onClick={() => handlePageChange(previousUrl)} />
            )}
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => {
                  const url = `${apiUrl}/api/posts/bysubcategory1/${subCategory1Id}/?page=${
                    index + 1
                  }`;
                  fetchPosts(url);
                }}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            {nextUrl && (
              <Pagination.Next onClick={() => handlePageChange(nextUrl)} />
            )}
          </Pagination>
        </div>
      </Container>
      <div className="gomeabout-footer">
        <Footer />
      </div>
    </>
  );
}

export default Posts;

{
  /* <div className="carousel-container">
                    <Carousel className="files-ca">
                      {post.files.map((file, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100 carousel-image"
                            src={file.file}
                            alt={`Slide ${index + 1}`}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div> */
}
