import React, { useState } from 'react';
import axios from 'axios';
import apiUrl from '../../Config'; // Make sure this points to your backend URL
import '../Login/EmployeeLogin.css';
import { Button, Container, Form, Image } from 'react-bootstrap';
import klogo from '../../../Assets/letter-k.png';
import { useNavigate } from 'react-router-dom';

const EmployeeLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();   

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/api/employeelogin/`, {
        username,
        password
      });
      const { token, employee_user } = response.data;
      localStorage.setItem('authToken', token); 
      setMessage('Login successful!');
      setTimeout(() => {
        navigate("/dashboard"); 
      }, 1000);

    } catch (error) {
      setMessage('Invalid username or password');
    }
  };

  return ( 
    <Container className="main-container">
      <Container className="left-container">
        
        <Image
          src={klogo}
          className="lom-im"
          alt="Logo"
          width="350"
          height="350"
        />
      </Container>
      <div className="right-container">
      <h2>Employee Login</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          className='custom-input-s'
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="custom-input-s"
          required
        />
        <Button variant="light" className="signup" type="submit">
            Login
          </Button>
      </form>
     
    </div>
    </Container>
  );
};

export default EmployeeLogin;
