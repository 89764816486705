import React from "react";
import Footer from "../../Footer/Footer";
import Base from "../../Base/Base";
import "../Term/Term.css";
function Term() {
  return (
    <>
      <Base />
      <div style={{ marginTop: "170px" }}></div>

      <div className="terms-container">
        <h1 className="term-h1">Terms and Conditions</h1>
        <section>
          <h2 className="term-h2">1. Introduction</h2>
          <p className="term-p">
            Welcome to www.klassifies.com. By accessing or using our services,
            you agree to comply with and be bound by the following terms and
            conditions. Please read these terms carefully before using our
            services.
          </p>
        </section>

        <section>
          <h2 className="term-h2">2. User Responsibilities</h2>
          <p className="term-p">
            <strong>2.1.</strong> You must be at least 18 years old to use our
            services.
          </p>
          <p className="term-p">
            <strong>2.2.</strong> You are responsible for the accuracy and
            legality of the content you submit for posting on the Website.
          </p>
          <p className="term-p">
            <strong>2.3.</strong> You agree not to submit any content that
            violates our policies, as outlined in Section 4.
          </p>
        </section>

        <section>
          <h2 className="term-h2">3. Advertisement Posting</h2>
          <p className="term-p">
            <strong>3.1.</strong> We reserve the right to refuse or remove any
            advertisement without providing any reason.
          </p>
          <p className="term-p">
            <strong>3.2.</strong> All advertisements are subject to approval
            before being posted on the Website.
          </p>
          <p className="term-p">
            <strong>3.3.</strong> We do not endorse or guarantee the accuracy of
            any content posted on the Website.
          </p>
        </section>

        <section>
          <h2 className="term-h2">4. Violation Content Policy</h2>
          <p className="term-p">
            <strong>4.1.</strong> The following content is strictly prohibited
            and may result in the rejection or removal of the advertisement:
          </p>
          <ul className="term-ul">
            <li className="term-li">a. Content that violates applicable laws or regulations.</li>
            <li className="term-li">
              b. Discriminatory or offensive content based on race, ethnicity,
              religion, gender, sexual orientation, disability, etc.
            </li>
            <li className="term-li">
              c. Content that infringes on the intellectual property rights of
              others.
            </li>
            <li className="term-li">d. Misleading or fraudulent content.</li>
            <li className="term-li">e. Content promoting illegal activities.</li>
            <li className="term-li">f. Adult content or explicit material.</li>
            <li className="term-li">g. Content that violates the privacy rights of individuals.</li>
          </ul>
          <p className="term-p">
            <strong>4.2.</strong> We reserve the right to take appropriate legal
            action against individuals or entities involved in submitting or
            promoting prohibited content.
          </p>
        </section>

        <section>
          <h2 className="term-h2">5. Data Protection and Privacy</h2>
          <p className="term-p">
            <strong>5.1.</strong> We collect and process personal data in
            accordance with our Privacy Policy, which is available on the
            Website.
          </p>
          <p className="term-p">
            <strong>5.2.</strong> By using our services, you consent to the
            collection and processing of your personal data as outlined in our
            Privacy Policy.
          </p>
        </section>

        <section>
          <h2 className="term-h2">6. Limitation of Liability</h2>
          <p className="term-p">
            <strong>6.1.</strong> We are not responsible for the accuracy,
            legality, or legitimacy of the content posted on the Website.
          </p>
          <p className="term-p">
            <strong>6.2.</strong> We reserve the right to refund or refuse a
            refund for any payment made for advertisements that violate our
            policies.
          </p>
          <p className="term-p">
            <strong>6.3.</strong> We shall not be liable for any direct,
            indirect, incidental, consequential, or punitive damages arising out
            of or in connection with the use of our services.
          </p>
        </section>

        <section>
          <h2 className="term-h2">7. Miscellaneous</h2>
          <p className="term-p">
            <strong>7.1.</strong> These terms and conditions may be updated from
            time to time, and the latest version will be posted on the Website.
          </p>
          <p className="term-p">
            <strong>7.2.</strong> We reserve the right to modify, suspend, or
            terminate our services at any time.
          </p>
          <p className="term-p">
            <strong>7.3.</strong> Any disputes arising from the use of our
            services will be subject to the laws of India.
          </p>
        </section>

        <section>
          <h2 className="term-h2">8. User Account</h2>
          <p className="term-p">
            <strong>8.1.</strong> To post advertisements, users may be required
            to create an account. You are responsible for maintaining the
            confidentiality of your account credentials and for all activities
            that occur under your account.
          </p>
          <p className="term-p">
            <strong>8.2.</strong> If you believe that your account has been
            compromised, you must notify us immediately.
          </p>
        </section>

        <section>
          <h2 className="term-h2">9. Third-Party Links</h2>
          <p className="term-p">
            <strong>9.1.</strong> The Website may contain links to third-party
            websites or services that are not owned or controlled by us.
          </p>
          <p className="term-p">
            <strong>9.2.</strong> We do not endorse or assume any responsibility
            for the content, privacy policies, or practices of any third-party
            websites or services. You acknowledge and agree that we shall not be
            responsible or liable, directly or indirectly, for any damage or
            loss caused or alleged to be caused by or in connection with the use
            of or reliance on any such content, goods, or services available on
            or through any third-party websites or services.
          </p>
        </section>

        <section>
          <h2 className="term-h2">10. Indemnification</h2>
          <p className="term-p">
            <strong>10.1.</strong> You agree to indemnify and hold [Your
            Company] harmless from any claims, demands, liabilities, damages, or
            expenses (including attorneys' fees) arising from your use of the
            Website, your violation of these terms, or your violation of any
            rights of a third party.
          </p>
        </section>

        <section>
          <h2 className="term-h2">11. Severability</h2>
          <p className="term-p">
            <strong>11.1.</strong> If any provision of these terms is found to
            be invalid or unenforceable, the remaining provisions will continue
            to be valid and enforceable.
          </p>
        </section>

        <section>
          <h2 className="term-h2">12. Contact Information</h2>
          <p className="term-p">
            <strong>12.1.</strong> For any questions about these terms and
            conditions or other inquiries, you can contact us at{" "}
            <a href="mailto:contactus@klassifies.com">
              contactus@klassifies.com
            </a>
            .
          </p>
        </section>

        <p className="term-p">
          By using our services, you acknowledge that you have read, understood,
          and agreed to these terms and conditions. If you do not agree with any
          part of these terms, please do not use our services.
        </p>
      </div>
      <div className="about-footer">
        <Footer />
      </div>
    </>
  );
}

export default Term;
