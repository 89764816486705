import React, { useState } from 'react';
import axios from 'axios';
import apiUrl from '../../Config'; // Make sure this points to your backend URL
import '../Login/KLogin.css';
import { Button, Container, Form, Image } from 'react-bootstrap';
import klogo from '../../../Assets/letter-k.png';
import { useNavigate } from 'react-router-dom';

const KLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();   

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/api/adminlogin/`, {
        username,
        password
      });
      const { access_token, kadmin_user } = response.data;
      console.log('Response Data:', response.data);

    // Stores the authentication token in localStorage
    localStorage.setItem('authAToken', access_token);

    // Log what is being stored in localStorage
    console.log('Stored Token:', localStorage.getItem('authAToken'));

    // Optionally, you can also log the user details if needed
    console.log('Admin User:', kadmin_user);

    // Sets a success message
    setMessage('Login successful!');
      setTimeout(() => {
        navigate("/adash"); 
      }, 1000);

    } catch (error) {
      setMessage('Invalid username or password');
    }
  };

  return ( 
    <Container className="main-container">
      <Container className="left-container">
        
        <Image
          src={klogo}
          className="lom-im"
          alt="Logo"
          width="350"
          height="350"
        />
      </Container>
      <div className="right-container">
      <h2>Klassifies Admin Login</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          className='custom-input-s'
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="custom-input-s"
          required
        />
        <Button variant="light" className="signup" type="submit">
            Login
          </Button>
      </form>
     
    </div>
    </Container>
  );
};

export default KLogin;
