import React, { useState } from 'react';
import axios from 'axios';
import apiUrl from '../../Config';  
import '../Register/EmployeeRegister.css'; 
import { Button, Container, Form, Image } from 'react-bootstrap';
import klogo from '../../../Assets/letter-k.png';
import EyeIcon from '../../../Assets/eye.png';
import EyeSlashIcon from '../../../Assets/eye-slash.png';
import { useNavigate } from 'react-router-dom';


const EmployeeRegister = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();   
  const [errors, setErrors] = useState({});
  const Eye = () => <img src={EyeIcon} alt="Eye Icon" />;
  const EyeSlash = () => <img src={EyeSlashIcon} alt="Eye Slash Icon" />;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!profilePhoto) {
      setErrors({ profilePhoto: "Profile photo is required" });
      return;
    }


    const formData = new FormData();
    formData.append('username', username);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('profile_photo', profilePhoto);

    try {
      const response = await axios.post(`${apiUrl}/api/employeeregister/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Registration successful!');
     
      setErrors({}); // Clear errors on success
      setTimeout(() => {
        navigate("/employeelogin"); // Redirect to login page
      }, 1000);
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data); // Capture error messages
      } else {
        setMessage('An unexpected error occurred.');
      }
    }
  };

  return (
    <Container className="main-container">
      <Container className="left-container">
        <Image
          src={klogo}
          className="lom-im"
          alt="Logo"
          width="350"
          height="350"
        />
      </Container>
      <div className="right-container">
        <h2>Employee Registration</h2>

        {/* Display success or general error message */}
        {message && <p className="message">{message}</p>}

        {/* Display all field-specific error messages */}
        {Object.keys(errors).map((key) => (
          <p key={key} className="error-message">{errors[key]}</p> 
        ))}

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            className="custom-input-s"
            required
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="custom-input-s"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="custom-input-s"
            required
          />
          <div className="f-text" style={{ cursor: 'pointer' }}>
            <label htmlFor="profile-photo-input" className="f-text-sub-1">
              Choose Profile Picture
            </label>
            <input
              id="profile-photo-input"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => setProfilePhoto(e.target.files[0])}
            />
          </div>
          <Button variant="light" className="signup" type="submit">
            Register
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default EmployeeRegister;
