import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import apiUrl from "../Config";
import "./Home.css";
import { Container, Spinner, Card, Carousel } from "react-bootstrap";
import Footer from "../Footer/Footer";
import BaseMain from "../Base/Base";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";
import TopMessageBar from "../KAdmin/TopMessageBar/TopMessageBar.js";
import VideoAd from "../KAdmin/VideoAd/VideoAd.js";
import HomeImgAd from "../KAdmin/HomeImgAd/HomeImgAd.js";

function Home() {
  const [ip, setIp] = useState(null);
  const [geoData, setGeoData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const categoryScrollRef = useRef(null);
  const [topBarVisible, setTopBarVisible] = useState(true);
  // Fetch user's IP address
  const fetchIpAddress = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/api_ip/get_ip/`);
      setIp(response.data.ip);
    } catch (error) {
      console.error("Error fetching IP:", error);
      setError(error);
    }
  };

  // Fetch geolocation based on IP address
  const fetchGeoLocation = async () => {
    if (ip) {
      try {
        const response = await axios.get(`${apiUrl}/api/geolocation/${ip}/`);
        console.log(response);
        setGeoData(response.data);
      } catch (error) {
        console.error("Error fetching geolocation:", error);
        setError(error);
      }
    }
  };

  const handleCloseTopBar = () => {
    setTopBarVisible(false);
  };

  useEffect(() => {
    fetchIpAddress();
  }, []);

  useEffect(() => {
    fetchGeoLocation();
  }, [ip]);

  const handleSearch = async (query) => {
    setLoading(true);
    setError(null);
    setSearchQuery(query);
    try {
      const response = await axios.get(`${apiUrl}/api/ads/search/`, {
        params: { query },
      });
      setSearchResults(response.data);
    } catch (err) {
      console.error("Error fetching ads:", err);
      setError("An error occurred while fetching the ads.");
    }
    setLoading(false);
  };

  return (
    <>
     
      <Container className="home-base">
      
        <BaseMain onSearch={handleSearch} />

        <div className="homecontentcontainer">
          <div className="hoepa">
          <p className="homepagecontent">
            <strong>
              Welcome to Klassifies.com – Your Ultimate Local Classifieds Hub!
            </strong>
          </p>
          <p>
            Discover a world of opportunities with Klassifies.com, your go-to
            platform for buying, selling, and connecting in your local
            community. Whether you’re looking to find the perfect item, explore
            new services, or connect with others nearby, Klassifies.com makes it
            easy and convenient.
          </p>
          <p>
            <strong>Why Choose Klassifies.com?</strong>
          </p>
          <ul className="features-list">
            <li>
              <strong>Easy Navigation:</strong> Our user-friendly interface
              ensures that finding what you need is a breeze. Browse through
              categories, search for specific items, and connect with local
              sellers effortlessly.
            </li>
            <li>
              <strong>Wide Range of Categories:</strong> From real estate and
              job opportunities to personal services and community events,
              Klassifies.com offers a diverse range of categories to meet all
              your needs.
            </li>
            <li>
              <strong>Local Focus:</strong> Connect with people and businesses
              in your area. Our platform is designed to help you find local
              deals, services, and connections that matter to you.
            </li>
            <li>
              <strong>Free and Simple:</strong> Listing your items or services
              is absolutely free. Enjoy a straightforward process with no hidden
              fees, making it easier for you to get the most out of your
              Klassifies.com experience.
            </li>
          </ul>
          <p>
            <strong>Get Started Today!</strong>
          </p>
          <ul className="get-started-list">
            <li>
              <strong>Browse Listings:</strong> Explore our extensive range of
              categories and find exactly what you’re looking for.
            </li>
            <li>
              <strong>Post an Ad:</strong> Create a free listing for items you
              want to sell or services you offer. Reach a wide audience in your
              community with just a few clicks.
            </li>
            <li>
              <strong>Connect and Communicate:</strong> Use our messaging system
              to communicate directly with potential buyers or sellers. Arrange
              meetings, negotiate prices, and make deals securely.
            </li>
          </ul>
          </div>
          <div className="adbox">
          <HomeImgAd />
        </div>
        </div>

        
        <VideoAd />
        
      </Container>
      <div className="career-footer">
        <Footer />
      </div>
  
    </>
  );
}

export default Home;
