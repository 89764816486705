import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Signup from "./Components/Auth/Signup";
import Login from "./Components/Auth/Login";
import BaseMain from "./Components/Base/Base";
import UserDetailsComponent from "./Components/UserDetails/UserDetails";
import CreateAd from "./Components/Ads/CreateAd";
import Home from "./Components/HomePage/Home";
import About from "./Components/FooterContent/About/About";
import Contact from "./Components/FooterContent/Contact/Contact";
import Locations from "./Components/FooterContent/Locations/Locations";
import Term from "./Components/FooterContent/Term/Term";
import Careers from "./Components/FooterContent/Careers/Careers";
import Posts from "./Components/Posts/Posts";
import SearchResults from "./Components/SearchResults/SearchResults";
import PasswordChange from "./Components/Password/PasswordChange";
import PasswordResetConfirm from "./Components/Password/PasswordRestConfirm";
import PasswordResetRequest from "./Components/Password/PasswordResetRequest";
import PostDetailModel from "./Components/PostDetailsModel/PostDetailModel";
import EmployeeRegister from "./Components/Employee/Register/EmployeeRegister";
import EmployeeLogin from "./Components/Employee/Login/EmployeeLogin";
import Dashboard from "./Components/Employee/Dashboard/Dashboard";
import KLogin from "./Components/KAdmin/Login/KLogin.js";
import KRegister from './Components/KAdmin/Register/KRegister.js';
import ADash from './Components/KAdmin/ADash/ADash.js';
import TopMessageBar from "./Components/KAdmin/TopMessageBar/TopMessageBar.js";
import VideoAd from "./Components/KAdmin/VideoAd/VideoAd.js";


import ReactGA from 'react-ga4';
import HomeImgAd from "./Components/KAdmin/HomeImgAd/HomeImgAd.js";

function App() {
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("accessToken");
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    }
  }, []);

  return (
    <div className="App">

      <Router>
        <Routes>

          {/* Users */}
          <Route path="/" element={accessToken ? <Navigate to="/Home" /> : <Home />} />
          <Route path="/basemain" element={<BaseMain />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/userdetails" element={<UserDetailsComponent />} />
          
          {/* Ads */}
          <Route path="/CreateAd" element={<CreateAd />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/posts/:subCategory1Id/:subCategory" element={<Posts />} />
          <Route path="/ads/:postId" element={<PostDetailModel />} />

          {/* Password */}
          <Route path="/password-reset-request" element={<PasswordResetRequest />} /> 
          <Route path="/password/reset/confirm/:uidb64/:token/" element={<PasswordResetConfirm />} />
          <Route path="/password-change" element={<PasswordChange />} />
          

          {/* Employee */}
          <Route path="/Employeeregister" element={<EmployeeRegister />} />
          <Route path="/Employeelogin" element={<EmployeeLogin />} />
          <Route path="/dashboard" element={<Dashboard />} />

          {/* Admin */}
          <Route path="/kregister" element={<KRegister />} />
          <Route path="/klogin" element={<KLogin />} />
          <Route path="/adash" element={<ADash />} />


          {/* Footer Contents */}
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/Locations" element={<Locations />} />
          <Route path="/Term" element={<Term />} />
          <Route path="/Careers" element={<Careers />} />

          {/* Ads from Client */}
          <Route path="/topmessagebar" element={<TopMessageBar />} />
          <Route path="/topvideoad" element={<VideoAd />} />
          <Route path="/homeimgads" element={<HomeImgAd />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
