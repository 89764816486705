// Loading.js
import React from 'react';
import './Loading.css';

const Loading = () => (
  <div className="loading-container">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);

export default Loading;