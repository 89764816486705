import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TopMessageBar.css'; // Import your CSS file
import apiUrl from '../../Config';
import CloseButton from 'react-bootstrap/CloseButton';


const TopMessageBar = ({onClose}) => {
  const [adText, setAdText] = useState('');

  useEffect(() => {
    // Fetch the top ad message from the API
    const token = localStorage.getItem("authAToken");
    axios.get(`${apiUrl}/api/admin-top-ads/`)
    .then(response => {
      const ad = response.data[0]; // Assuming you only want the first ad message
      if (ad) {
        setAdText(ad.ad_text);
      }
    })
    .catch(error => {
      console.error('Error fetching the top ad:', error);
    });
  }, []);

  const handleClose = () => {
    setAdText('');
    onClose();
  };

  // Do not render the bar if there's no ad text
  if (!adText) return null;

  return (
    <div className="top-message-bar">
      <span className='adte'>{adText}</span>
      <span onClick={handleClose} className='adclose'><CloseButton /> </span>
    </div>
  );
};

export default TopMessageBar;
