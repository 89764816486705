import React from "react";
import Footer from "../../Footer/Footer";
import Base from "../../Base/Base";
import "../Careers/Careers.css"

function Careers() {
  return (
    <div className="career-container">
      <Base />
      <div className="career-content-container" style={{ marginTop: "150px" }}>
        <h4 className="career-head">Update in Progress</h4>
      </div>
      <div className="career-footer">
        <Footer />
      </div>
    </div>
  );
}

export default Careers;
