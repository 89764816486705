// PasswordResetConfirm.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import apiUrl from '../Config';
import "../Password/Password.css";
import Footer from '../Footer/Footer';
import Base from '../Base/Base';

const PasswordResetConfirm = () => {
  const { uidb64, token } = useParams();
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/api/password/reset/confirm/${uidb64}/${token}/`, {
        new_password1: password1,
        new_password2: password2,
      });
      setMessage('Password has been reset.');
    } catch (error) {
      setMessage('Error resetting password.');
    }
  };

  return (
    <>

    <div className='resetbody'>
      <Base />
    <div style={{ marginTop: "470px" }}></div>

      <form className='resetconfirmform' onSubmit={handleSubmit}>
        <h3>Change Password</h3>
        <input
          type="password"
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
          placeholder="New password"
          className='password1field'
          required
        />
        <input
          type="password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          placeholder="Confirm new password"
          className='password2field'

          required
        />
        <button className='reset-button' type="submit">Reset Password</button>
      </form>
      {message && <p>{message}</p>}

      <div className="about-footer">
        <Footer />
      </div>
      </div>
    </>
  );
};

export default PasswordResetConfirm;
