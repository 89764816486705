import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CloseButton from 'react-bootstrap/CloseButton';
import './VideoAd.css'; // Updated CSS file
import apiUrl from '../../Config';


const VideoAd = () => {
    const [videoUrl, setVideoUrl] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    
    useEffect(() => {
        const token = localStorage.getItem("authAToken");
        
        // Fetch the video URL after 20 seconds
        setTimeout(() => {
          axios.get(`${apiUrl}/api/admin-videos-ads/`)
          .then(response => {
            const ad = response.data[0]; // Get the first ad video
            if (ad) {
              setVideoUrl(ad.ad_video);
              setShowVideo(true); // Show the video after fetching the URL
            }
          })
          .catch(error => {
            console.error('Error fetching the video ad:', error);
          });
        }, 10000); // 20 seconds delay
      }, []);
  
    const handleClose = () => {
      setVideoUrl(''); 
    };
  
    // Do not render the video ad if there's no video URL
    if (!videoUrl) return null;
  
    return (
      <div className="video-ad-container">
        <div className="video-ad-content">
          <CloseButton onClick={handleClose} className="video-ad-close"/>
          <video className="video-ad" controls autoPlay>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  };
  
  export default VideoAd;