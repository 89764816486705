import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import apiUrl from "../Config";
import "../Base/BaseM.css";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../Assets/letter-k.png";
import "../Base/BaseM.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import placeholderImage from "../../Assets/placeholder.jpg";
import { Container, Navbar, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import debounce from "lodash.debounce";
import TopMessageBar from "../KAdmin/TopMessageBar/TopMessageBar.js";

function BaseMain({ onSearch }) {
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories1, setSubCategories1] = useState({});
  const [subCategories2, setSubCategories2] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [query, setQuery] = useState("");
  const [ads, setAds] = useState([]);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [isMessageBarVisible, setIsMessageBarVisible] = useState(true);

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const categoryScrollRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);

  const handleSearch = () => {
    if (searchQuery.trim() === "") return;
    navigate(`/search?query=${searchQuery}`);
  };

  const fetchMainCategories = async () => {
    try {
      const url = `${apiUrl}/api/main-categories/`;
      console.log("Fetching from URL:", url);

      const response = await axios.get(url);
      console.log("Response data:", response.data);

      setMainCategories(response.data);
    } catch (error) {
      console.error("Error fetching main categories:", error);
      setError(error);
    }
  };

  const fetchSubCategories1 = useCallback(
    debounce(async (mainCategoryId) => {
      if (!subCategories1[mainCategoryId]) {
        try {
          const response = await axios.get(
            `${apiUrl}/api/main-categories/${mainCategoryId}/`
          );
          setSubCategories1((prev) => ({
            ...prev,
            [mainCategoryId]: response.data.SubCategories,
          }));
        } catch (error) {
          console.error(
            `Error fetching subcategories1 for ${mainCategoryId}:`,
            error
          );
          setError(error);
        }
      }
    }, 300), // Debounce time in milliseconds
    [subCategories1]
  );

  // Fetch subcategories level 2 based on subcategory level 1
  const fetchSubCategories2 = async (mainCategoryId, subCategory1Id) => {
    if (!subCategories2[subCategory1Id]) {
      try {
        const response = await axios.get(
          `${apiUrl}/api/main-categories/${mainCategoryId}/subcategories2/`
        );
        setSubCategories2((prev) => ({
          ...prev,
          [subCategory1Id]: response.data,
        }));
      } catch (error) {
        console.error(
          `Error fetching subcategories2 for ${subCategory1Id}:`,
          error
        );
        setError(error);
      }
    }
  };

  const handleMainCategoryHover = async (mainCategoryId, event) => {
    const element = event.currentTarget;
    const { top, left, height } = element.getBoundingClientRect();

    const dropdownTop = top + window.scrollY + height;
    const dropdownLeft = left + window.scrollX;

    setDropdownPosition({ top: dropdownTop, left: dropdownLeft });

    setSelectedMainCategory(mainCategoryId);
    fetchSubCategories1(mainCategoryId);
    console.log("Selected Main Category ID:", mainCategoryId);
  };

  const handleSubCategory1Click = (subCategory1Id, subCategory) => {
    console.log(`Subcategory1 clicked: ${subCategory1Id}, ${subCategory}`);
    fetchSubCategories2(selectedMainCategory, subCategory1Id);
    setSelectedMainCategory(null);
    console.log(
      `Navigating to URL: /posts/${subCategory1Id}/${encodeURIComponent(
        subCategory
      )}`
    );
    navigate(`/posts/${subCategory1Id}/${encodeURIComponent(subCategory)}`);
  };

  const handleScrollLeft = () => {
    console.log("Scroll Left Button Clicked");
    if (categoryScrollRef.current) {
      console.log("Scrolling left");
      categoryScrollRef.current.scrollBy({ left: -100, behavior: "smooth" });
    }
  };

  const handleScrollRight = () => {
    console.log("Scroll Right Button Clicked");
    if (categoryScrollRef.current) {
      console.log("Scrolling right");
      categoryScrollRef.current.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  const handleMouseLeaveDropdown = () => {
    setSelectedMainCategory(null);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSelectedMainCategory(null);
    }
  };

  useEffect(() => {
    fetchMainCategories();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Fetch subcategories when a main category is selected
    if (selectedMainCategory) {
      fetchSubCategories1(selectedMainCategory);
    }
  }, [selectedMainCategory]);

  useEffect(() => {
    const fetchData = async () => {
      if (accessToken) {
        try {
          setLoading(true);
          const cachedUserDetails = JSON.parse(
            localStorage.getItem("userDetails")
          );
          if (cachedUserDetails) {
            setUserDetails(cachedUserDetails);
          } else {
            const userId = localStorage.getItem("pk");
            if (userId) {
              const response = await axios.get(
                `${apiUrl}/api/userdetail/${userId}/`,
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );

              if (response.status === 200) {
                setUserDetails(response.data);
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify(response.data)
                );
              } else {
                console.error(
                  "Failed to fetch user details. Status code:",
                  response.status
                );
              }
            } else {
              console.error("userId is null");
            }
          }
        } catch (error) {
          console.error("Error during fetching user details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [accessToken]);

  let username = userDetails ? userDetails.username : "...";
  username = username.charAt(0).toUpperCase() + username.slice(1);
  const handleTopMessageBarClose = () => {
    setIsMessageBarVisible(false);
  };
  return (
    <>
    {isMessageBarVisible && (
        <TopMessageBar onClose={handleTopMessageBarClose} />
      )}
       <Navbar
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top custom-navbar"
        style={{
          marginTop: isMessageBarVisible ? '20px' : '0px',  // Adjust this height according to the TopMessageBar's height
          transition: "margin-top 0.3s ease",  
        }}
      >
        <Navbar.Brand href="/Home">
          <Image src={Logo} alt="Logo" width="40" height="40" />
        </Navbar.Brand>

        <InputGroup className="rectangle-8-57B customfont">
          <FormControl
            style={{ fontFamily: "Montserrat, sans-serif" }}
            placeholder="I am looking for..."
            aria-label="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <Button className="button-s" onClick={handleSearch}>
            <FontAwesomeIcon icon={faSearch} className="icon-s" />
          </Button>
        </InputGroup>

        {accessToken ? (
          <Container className="loginprofile">
            <div className="main-details a-r flex-container">
              <Link to="/userdetails" className="profile-link">
                <img
                  src={
                    userDetails && userDetails.profile_photo
                      ? `${apiUrl}${userDetails.profile_photo}`
                      : placeholderImage
                  }
                  alt="Profile"
                  className="rounded-circle profile-image"
                />
              </Link>
              <Container className="words">
                <div className="det-ma">
                  <p className="username">
                    <Link to="/userdetails" className="user-na">
                      {username}
                    </Link>
                  </p>
                  <p className="ad-bm">
                    <Link to="/CreateAd" className="cu-bm">
                      Create Ad!
                    </Link>
                  </p>
                </div>
              </Container>
            </div>
          </Container>
        ) : (
          <Container className="withoutlogin">
            <div className="g-s-k">
              <p className="hi-guest-login-hPT">
                <span className="hi-guest-login-hPT-sub-0">Hi Guest |</span>
                <span className="hi-guest-login-hPT-sub-1">
                  <Link className="cu-fo-col" to="/Login">
                    Login
                  </Link>
                </span>
              </p>
              <div>
                <p className="create-ad-zYu">
                  <Link className="cu-fo-col" to="/CreateAd">
                    Create Ad!
                  </Link>
                </p>
              </div>
            </div>
          </Container>
        )}
      </Navbar>

      <div className="cat-scroll-wrapper" style={{
          marginTop: isMessageBarVisible ? '20px' : '0px',  // Adjust this height according to the TopMessageBar's height
          transition: "margin-top 0.3s ease",  
        }}>
        <div className="three-wide-column">
          <Icon name="angle left" size="big" onClick={handleScrollLeft} />
        </div>
        <div className="cat-scroll" ref={categoryScrollRef}>
          <ul className="category-list">
            {Array.isArray(mainCategories) &&
              mainCategories.map((category) => (
                <li
                  key={category.id}
                  onMouseEnter={(event) =>
                    handleMainCategoryHover(category.id, event)
                  }
                  onClick={(event) =>
                    handleMainCategoryHover(category.id, event)
                  }
                  // onMouseLeave={handleMouseLeaveDropdown}
                  className="category-item"
                >
                  {category.MainCategory}
                </li>
              ))}
          </ul>
        </div>

        <div className="three-wide-column">
          <Icon name="angle right" size="big" onClick={handleScrollRight} />
        </div>
      </div>

      {selectedMainCategory && (
        <div
          ref={dropdownRef}
          className="subcategories-dropdown"
          style={{
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
            width: `${dropdownPosition.width}px`,
            position: "absolute",
            zIndex: 1000,
          }}
          onMouseLeave={handleMouseLeaveDropdown}
        >
          <ul>
            {Array.isArray(subCategories1[selectedMainCategory]) &&
              subCategories1[selectedMainCategory].map((subcat1) => (
                <li
                  key={subcat1.id}
                  onClick={() =>
                    handleSubCategory1Click(subcat1.id, subcat1.SubCategory)
                  }
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {subcat1.SubCategory}
                  <span style={{ color: "blue", marginLeft: "auto" }}>
                    {subcat1.post_count}
                  </span>{" "}
                  {Array.isArray(subCategories2[subcat1.id]) && (
                    <ul className="subcategories2-list">
                      {subCategories2[subcat1.id].map((subcat2) => (
                        <li
                          key={subcat2.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {subcat2.SubCategory}
                          <span style={{ color: "blue" }}>
                            {subcat2.post_count} posts
                          </span>{" "}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        </div>
      )}
 
    </>
  );
}

export default BaseMain;
