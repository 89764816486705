import React from "react";
import Footer from "../../Footer/Footer";
import Base from "../../Base/Base";

function Locations() {
    return (
        <div className="about-container">
          <Base />
          <div className="about-content-container" style={{ marginTop: "150px" }}>
            <h4 className="location-head">Update in progress</h4>
            
          </div>
          <div className="about-footer">
            <Footer />
          </div>
        </div>
      );
}

export default Locations;